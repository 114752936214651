import { Button, Divider, Dropdown, Input, Modal, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { MeetingTypeSelectorItems } from '../../../../../../features/home/tabs/UpcomingsTab/components/MeetingTypeSelector';
import { ChevronDown } from '../../../../../../features/shared/components/Icons/ChevronDown';
import { Questions } from '../Questions';
import { colors } from '../../../../../../theme/colors';
import { v4 } from 'uuid';
import { Framework, Question } from '../../../../../../features/frameworks/type';

import Style from './style';
const { ModalContent, Item } = Style;

interface Props {
  open: boolean;
  loading: boolean;
  framework: Framework;
  onClose: () => void;
  onSave: (framework: Framework) => void;
}

export const FrameworkModal: React.FC<Props> = ({ open, framework, loading, onClose, onSave }) => {
  const [fwk, setFwk] = useState<Framework>(framework);

  const handleUpdateQuestion = (question: Question) => {
    const questions = fwk.questions.map(q => (q.id === question.id ? question : q));
    setFwk({ ...fwk, questions });
  };

  const handleRemoveQuestion = (id: string) => {
    setFwk({ ...fwk, questions: fwk.questions.filter(q => q.id !== id) });
  };

  useEffect(() => {
    setFwk(framework);
  }, [framework]);

  return (
    <Modal
      footer={false}
      open={open}
      width={550}
      onCancel={onClose}
      title={'Create New Framework'}
      closable={!loading}
    >
      <ModalContent>
        <Divider style={{ margin: '0px', borderWidth: '2px' }} />

        <Item>
          <h2>Framework Name*</h2>
          <Input
            required
            disabled={loading}
            value={fwk?.name || ''}
            placeholder="Framework Name"
            onChange={n => setFwk({ ...fwk, name: n.target.value })}
          />
        </Item>

        <Item>
          <h2>Meeting Type</h2>
          <div
            style={{
              display: 'flex',
              gap: '5px',
              height: 'auto',
              overflowX: 'auto',
              paddingBottom: '3px',
            }}
          >
            {fwk?.meetingType?.map(m => (
              <Tag
                key={m}
                closeIcon={!loading}
                onClose={() => {
                  setFwk({
                    ...fwk,
                    meetingType: [...(fwk.meetingType || []).filter(e => e !== m)],
                  });
                }}
              >
                {m}
              </Tag>
            ))}
          </div>
          <div style={{ display: 'flex', gap: '15px', width: ' 100%' }}>
            <Dropdown
              disabled={loading}
              menu={{
                items: MeetingTypeSelectorItems(m => {
                  if (!fwk?.meetingType?.includes(m)) {
                    setFwk({ ...fwk, meetingType: [...(fwk.meetingType || []), m] });
                  }
                }),
              }}
            >
              <Button style={{ width: '100%' }} disabled={loading}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ color: colors.gray[400] }}>Select a Meeting Type</div>
                  <ChevronDown />
                </div>
              </Button>
            </Dropdown>

            <Button
              disabled={!!!fwk?.meetingType?.length || loading}
              style={{ width: '40px' }}
              icon={<>X</>}
              onClick={() => setFwk({ ...fwk, meetingType: [] })}
            />
          </div>
        </Item>

        <Divider style={{ margin: '0px', borderWidth: '2px' }} />

        <Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px',
            }}
          >
            <h2>Framework Questions</h2>
            <Button
              size="small"
              disabled={loading}
              onClick={() =>
                setFwk({
                  ...fwk,
                  questions: [
                    ...fwk.questions,
                    {
                      id: v4(),
                      value: '',
                      context: null,
                      position: fwk.questions.length,
                    },
                  ],
                })
              }
            >
              + Add New Question
            </Button>
          </div>

          <Questions
            questions={fwk.questions}
            updateQuestion={question => handleUpdateQuestion(question)}
            removeQuestion={id => handleRemoveQuestion(id)}
          />
        </Item>

        <Divider style={{ margin: '0px', borderWidth: '2px' }} />

        <div style={{ display: 'flex', gap: '5px', justifyContent: 'end', width: '100%' }}>
          <Button
            disabled={loading}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || !!!fwk}
            loading={loading}
            type="primary"
            onClick={() => onSave(fwk)}
          >
            Save
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
